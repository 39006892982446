<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Form Data</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <form role="form" @submit="submitForm">
                <div class="card-body">
                    <p v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                    </p>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="control-label">Typeahead</label>
                            <input type="hidden" name="row_id" value="">
                            <input type="text" class="form-control" autocomplete="off" name="i_user" ref="user" placeholder="User" v-model="form.username">
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label"> </label>
                            <label class="checkbox control-label">
                                <input value="1" checked="checked" type="checkbox" name="active"> Active</label>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Date</label>
                            <datepicker v-model="form.date1" />
                            <small id="emailHelp" class="form-text text-muted">datepicker</small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Month</label>
                            <datepicker v-model="form.month" :options="{format:'mm/yyyy', startView: 'months',     minViewMode: 'months'}" />
                            <small id="emailHelp" class="form-text text-muted">datepicker</small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Daterange picker</label>
                            <input type="text" class="form-control" ref="daterange">
                            <small id="emailHelp" class="form-text text-muted">daterangepicker</small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">DateTime picker</label>
                            <date-picker  v-model="form.datetime" />
                            <small id="emailHelp" class="form-text text-muted">datetimepicker, enable time only</small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Time picker</label>
                            <timepicker  v-model="form.time" />
                            <small id="emailHelp" class="form-text text-muted">timepicker (simple)</small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Dropdown</label>
                            <v-select :options="countries" :reduce="opt => opt.code" v-model="form.selected"></v-select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Masked Input</label>
                            <masked-input  class="form-control" v-model="date" mask="11/11/1111" placeholder="dd/mm/yyyy" />
                        </div>


                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <button type="button" class="btn btn-secondary" v-on:click="testSwal">Swal Alert</button>
                        <button type="button" v-on:click="cekData" class="btn btn-default">Cek Data</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { authFetch } from '@/libs/hxcore';
import Bloodhound from '@/libs/typeahead/typeahead.bundle.min';
import Handlebars from 'handlebars';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import timepicker from '@/components/Timepicker';
import vSelect from 'vue-select';
import Swal from 'sweetalert2';
import Daterangepicker from 'daterangepicker';
import datePicker from 'vue-bootstrap-datetimepicker';
import VClockPicker from 'v-clock-picker';
import maskedInput from 'vue-masked-input';

import "vue-select/dist/vue-select.css";
import "daterangepicker/daterangepicker.css";
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import '@/libs/typeahead/typeahead.css';
export default {
    name: 'Form',
    data() {
        return {
            errors: [],
            countries: [{label: 'Canada', code: 'ca'}, {label: 'Indonesia', code: 'id'}],
            form: {
                name: '',
                opt1:'',
                username: '',
                email: '',
                date1:'2/3/2012',
                month:'02/2021',
                dt1:'',
                time:'11:21',
                datetime:'',
                dt2:'',
                selected: 'id',
                date: null
            }
        }
    },
    components: {
        datepicker,
        datePicker,
        timepicker,
        VClockPicker,
        vSelect,
        maskedInput
    },
    mounted() {
        var self = this;
        new Daterangepicker(this.$refs.daterange, {}, function(d1, d2){
            self.form.dt1 = d1.format('YYYY-MM-DD');
            self.form.dt2 = d2.format('YYYY-MM-DD');
            console.log(d1,d2)
        });
        

        var userElem = this.$refs.user;
        console.log('type', Bloodhound)
        var users = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('display_name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            prefetch: {
                url: '/examples/user_typeahead?order=trend',
                transport: function(url, onSuccess, onError) {
                    authFetch(url.url)
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            console.log(js)
                            onSuccess(js);
                        });
                }
            },
            remote: {
                url: '/examples/user_typeahead?search=%q',
                wildcard: '%q',
                transport: function(url, onSuccess, onError) {
                    authFetch(url.url)
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            console.log(js)
                            onSuccess(js);
                        });
                }
            }
        });
        $(userElem).typeahead(null, {
            name: 'users-account',
            display: 'display_name',
            source: users,

            templates: {
                empty: function() {
                    var q = userElem.value;
                    var strEmpty = '<div onclick="createUser();" class="tt-suggestion tt-selectable">';
                    strEmpty += '<i class="fa fa-plus"></i> Tambah <strong>"' + q + '"</strong>';
                    strEmpty += '</div>';
                    return strEmpty;
                },
                suggestion: Handlebars.compile('<div><strong>{{display_name}}</strong> – {{email}}</div>')
            }
        });
    },
    methods: {
        testSwal: function() {            
            Swal.fire('Test swal', 'Informasi', 'success')
        },
        submitForm: function(e) {
            alert(JSON.stringify(this.form))
            //if (this.form.name && this.form.username) {
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')

                authFetch('/examples/user', {
                        method: 'POST',
                        body: this.form
                    })
                    .then(res => {
                        if (res.status === 201) {
                            alert('OK');
                        } else if (res.status === 400) {
                            //let errorResponse = await res.json();
                            //this.errors.push(errorResponse.error);
                        }
                        return res.json();
                    })
                    .then(js => {
                        console.log(js)
                    });
            //}
            this.errors = [];
            if (!this.form.name) {
                this.errors.push('Name required.');
            }
            if (!this.form.username) {
                this.errors.push('Username required.');
            }
            e.preventDefault();
        },

        cekData: function() {

            /*authFetch('https://ibnux.github.io/BMKG-importer/cuaca/501303.json',
            {
                mode:'no-cors'
            })
                .then(res => {
                    return res.text().then(function(text) {
                        console.log(text)
    return text ? JSON.parse(text) : {}
  })
                })
                .then(js =>{
                    console.log(js)
                });*/

            authFetch('/examples/user', {
                method: 'POST'
            })
        }
    }
}
</script>
<style>
.twitter-typeahead {
    display: block !important;
}
</style>